import React from 'react'
import Layout from '../../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import * as styles from '../../pages/e-books.module.css'

const StatusPage = () => {
  return (
    <Layout>
      <section className={`${styles.jumbo} jumbo h-52 sm:h-72`}>
        <div className="container">
          <h1 className="text-5xl sm:text-8xl text-red-800">Status</h1>
        </div>
      </section>
      <section className="content pt-16">
        <div className="container mx-auto px-4 !max-w-[100%]">
          <div className="flex flex-col md:flex-row gap-12">
            <div className="w-full md:w-1/4">
              <StaticImage 
                src="../../images/status-cover.jpg"
                alt="Status Book Cover"
                className="w-full rounded-lg"
              />
            </div>
            <div className="w-full md:w-1/2">
              <div className="flex flex-col md:flex-row justify-between items-center gap-4 mb-4">
                <h2 className="text-3xl font-bold text-gray-900 !mt-0">The No BS Blueprint for Becoming a High-Value Man</h2>
                <a 
                  href="https://marciancx.gumroad.com/l/status"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block bg-red-800 text-white px-4 py-4 rounded-lg hover:bg-red-900 transition-colors whitespace-nowrap"
                >
                  Get your FREE copy!
                </a>
              </div>
              <div className="space-y-4">
                <p className="text-gray-700 text-base">
                  Every man wants to be respected, desired, and followed—<br />
                  but most have no idea what actually makes them high-value.
                </p>
                <p className="text-gray-700 text-base">
                  It's not money.<br />
                  It's not status.<br />
                  It's not luck.
                </p>
                <p className="text-gray-700 text-base">
                  It's <strong>security.</strong>
                </p>
                <p className="text-gray-700 text-base">
                  In the eyes of women, every attractive trait—<br />
                  muscle, income, posture, confidence—<br />
                  is just a signal that you can protect, provide, and lead.
                </p>
                <p className="text-gray-700 text-base">
                  This guide breaks down every core trait that matters,<br />
                  separates what you can and can't control,<br />
                  and gives you a step-by-step roadmap<br />
                  to become a man who commands attention everywhere he goes.
                </p>
                <p className="text-gray-700 text-base">
                  <strong>Inside, you'll learn:</strong>
                </p>
                <ul className="text-gray-700 text-base list-disc pl-4 space-y-2">
                  <li>The full list of traits that make a man attractive—and why they all signal security</li>
                  <li>What to fix first—short, medium, and long-term improvements that actually work</li>
                  <li>How to carry yourself like a high-value man—even before you hit your peak</li>
                </ul>
                <p className="text-gray-700 text-base mb-8">
                  <strong>Become that man. Starting now.</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default StatusPage 